<template>
<div id="tiXianManage">
  <!-- 头部搜索 -->
  <div class="bg-white searchDom flex align-center justify-between">
    <div class="flex justify-between align-start margin-bottom-24">
      <!-- <el-select @change="changeValue" style="margin-right: 24px;width: 120px;" v-model="txTabDat.income_state	"
        filterable placeholder="状态">
        <el-option v-for="item in this.$conf.txState" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
      <el-select @change="changeValue" style="margin-right: 24px;width: 120px;" v-model="txTabDat.role	" filterable
        placeholder="身份">
        <el-option v-for="item in this.$conf.txRole" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select> -->
      <el-select @change="changeTime" style="margin-right: 24px;width: 120px;" v-model="financeTime1" filterable
        placeholder="快捷时间">
        <el-option v-for="item in this.$conf.financeorderTime" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
      <!-- <div class="flex">
        <el-date-picker @change="changeTime" style="width: 360px;" v-model="financeTime2" type="daterange"
          range-separator="-" start-placeholder="开始日期" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd"
          :picker-options="pickerBeginDateBefore" :default-value="timeDefaultShow" end-placeholder="结束日期">
        </el-date-picker>
        <el-button type="primary" icon="el-icon-search" @click="changeTime"></el-button>
      </div> -->
    </div>
    <div class="flex align-center justify-end margin-bottom-24 header_search">
      <div class="flex align-center solid margin-right-24 padding-lr-16" style="height:32px;width: 300px;">
        <el-input @input="changeKeyword" v-model="txTabDat.keyword" placeholder="请输入用户ID/用户昵称查询"
          suffix-icon="search"></el-input>
        <i class="el-icon-search" style="cursor: pointer;" @click="changeValue"></i>
      </div>
      <el-button @click="exportData"
        style="height: 32px;width: 68px;padding: 0;color: #4E9F5B !important;border-color: #4E9F5B;">导出</el-button>
    </div>
  </div>
  <!-- 搜索列表 -->
  <div class="searchList">
    <div class="margin-bottom-24 padding-lr-16" style="background-color: #F5F6F7;">
      <!-- <el-collapse v-model="activeNames" @change="handleChange">
        <el-collapse-item style="background-color: #F5F6F7;" title="财务折线图" name="1">
          <echartLine typeLine="tixian" company="元" :xData="xData" :datas="datas" :key="timer"></echartLine>
        </el-collapse-item>
      </el-collapse> -->
       <span style="padding-right:20px">提现折线图</span>
       <el-switch v-model="stateValue" active-color="#07C160" inactive-color="#333"></el-switch><echartLine v-show="stateValue!=false" typeLine="caiwu" company="元" :xData="xData" :datas="datas" :key="timer"></echartLine> 
    </div>
    <div class="invoice-content">
        <ul class="row align-center margin-bottom-26 flex-start">
            <li class="bg-white flex justify-between margin-bottom-16"
               v-for="(v,i) in dataList" :key="i">
                <div>
                    <div class="flex justify-between content">
                      <div class="invoice-info flex justify-between">
                        <div class="flex">
                          <h4 class="mb-0">用户ID:</h4>
                          <p v-if="v.user_id==null||v.user_id==''" style="font-size: 14px;color: #333333;">{{v.zz_id}}</p>
                          <p v-if="v.user_id!=null&&v.user_id!=''" style="font-size: 14px;color: #333333;">{{v.user_id}}</p>
                        </div>
                        <div class="flex">
                          <h4 class="mb-0">用户昵称:</h4>
                          <p v-if="v.user_nickname == null || v.user_nickname==''">{{v.zz_acc}}</p>
                          <p v-if="v.user_nickname!=null&&v.user_nickname!=''">{{v.user_nickname}}</p>
                        </div>
                        <div class="flex">
                          <h4 class="mb-0">时间:</h4>
                          <p>{{v.income_create_time * 1000|getDate}}</p>
                        </div>
                        <div class="flex">
                          <h4 class="mb-0">支付宝账号:</h4>
                          <p>{{v.income_acc}}</p>
                        </div>
                        <div class="flex">
                          <h4 class="mb-0">提现金额:</h4>
                          <p>{{v.income_money}}</p>
                        </div>
                      </div>
                      <div class="right text-end align-center justify-between">
                        <span v-if="v.income_examine_state=='0'" class="text-white bg-danger">未打款</span>
                        <span v-if="v.income_examine_state=='1'" class="text-white bg-success">已打款</span>
                        <span v-if="v.income_examine_state=='2'" class="text-white bg-danger">已驳回</span>
                        <el-button v-if="v.income_examine_state=='0'" @click="incomeExamine1(v.income_id)"
                         style="color: #4E9F5B;" class="btn btn-primary mt-2 mr-2 toastr_top_left_btn" type="text" size="medium">打款</el-button>
                      </div>
                    </div>
                </div>
            </li>
        </ul>
    </div>
  </div>
  <!-- 分页功能 -->
    <!-- total	总条目数
    layout 组件布局，子组件名用逗号分隔 total, sizes, prev, pager, next, jumper
    page-sizes	每页显示个数选择器的选项设置 [10, 20, 30, 40]
    size-change	页数 改变时会触发
    current-change	每页码 改变时会触发
    current-page 当前页数，支持 .sync 修饰符 -->
    <!-- <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="listQuery.pageNo"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="listQuery.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="rows">
    </el-pagination> -->
    <!-- <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="listQuery.page"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="listQuery.limit"
      layout="total, sizes, prev, pager, next, jumper"
      :total="rows">
    </el-pagination> -->
    <!-- :page.sync= "listQuery.pageNo"  相当于 @update:page = "val =>listQuery.pageNo = val " -->

      <!-- 表格弹窗 -->
      <!-- <tableModel @changeList="changeList" @changeCurenPage="changeCurenPage" @closeTabModel='closeTabModel_' :showTabModel="showTabModel" :modelTitle="modelTitle"
      :tableList="tableList"/> -->  
  <div class="pageNum" id="pageNum">
    <Pagination
      v-show="rows>1"
      :total="rows"
      @change = "getFinanceList"
      :page.sync= "txTabDat.page"
      :limit.sync="txTabDat.limit"/>
  </div>    
</div>
</template>
<script>
import {incomeList,incomeExamine} from '@/api/http'
import Pagination from '@/components/pagination'
import tableModel from './component/tableModel.vue'
export default {
    data(){
      return{
        timer:'',
        textarea: '',
        num:0,
        stateValue:false,
        /* 修改日期选择器结束日期*/
        pickerBeginDateBefore: {
          disabledDate(time) {
            return time.getTime() > Date.now();
          },
        },
        /* 分页参数*/
        pages:1, //总页数
        rows:1, //总条数
        allwait:'',//待审批退款条数
        timeDefaultShow: "",
        dataList: [], //表格数据
        finance_money_count: '', //总计
        financeTime1: '', //快捷选择时间
        financeTime2: '', //日期范围
        txTabDat: {
          income_state: '',
          role: '',
          time: '',
          keyword: '',
          page: 1,
          limit: 10
        },
        //附件
        showImgModel: false,
        imgContent: [],
        //退款理由

        showInfoModel: false,
        content: '',

        dataList:[],//表格数据
        finance_money_count: '', //总计
        financeTime1: '', //快捷选择时间
        financeTime2: '', //日期范围
        //图标数据
        activeNames:[],
        xData: [], //X轴
        datas: [], //折线数据
        lists:[],
        myChart:''
      }
    },
    created(){
        this.timeDefaultShow = new Date();
        this.timeDefaultShow.setMonth(new Date().getMonth() - 1);
        this.getFinanceList()
    },
    methods:{
        //导出数据
        exportData(){
          this.txTabDat.limit = 9999
          this.changeValue()
        },
        //折叠面板
        /* 折叠面板*/
        handleChange(val) {
          console.log(val);
          this.timer = new Date().getTime()
        }, 
        //获取提现列表
        getFinanceList(){
            let that = this
            let txDat = {}
            for (let item in this.txTabDat) {
              if (that.txTabDat[item] != '' && that.txTabDat[item] != '999') {
                txDat[item] = that.txTabDat[item]
              }
            }
            incomeList(txDat).then(res=>{
                console.log('提现列表',res.data)
                let {chart} = res.data.data
                if(chart){
                  this.xData = chart[0]
                  this.datas = chart[1]
                  console.log(this.xData,this.datas)
                }
                let {list,page,count,limit}=res.data.data
                console.log(res.data.code,list,page,count,limit)
                if(res.data.code == '1' && list){
                  this.rows = count
                  this.dataList = list
                  this.timer = new Date().getTime()
                }else if(res.data.code=='1'&&res.data.data.csv_file){
                  window.open(res.data.data.csv_file)
                  this.txTabDat.limit=this.rows
                }
            })
        },
          /* 改变获取列表参数 */
        changeValue() {
          this.txTabDat.page = 1
          this.getFinanceList()
        },
        /* 清空搜索框 */
        changeKeyword(e){
          if(e.length=='0'){
            this.changeValue()
          }
        },
        // /* 选择时间*/
        changeTime(e) {
          console.log(e)
          if (e == '999' || e == '7' || e == '15' || e == '30') {
            this.financeTime2 = ''
            this.txTabDat.time = e
          } else {
            this.financeTime1 = ''
            // var date = new Date(e[0])
            var time1 = Date.parse(new Date(e[0]+' 00:00:00')) / 1000
            var time2 = Date.parse(new Date(e[1]+' 23:59:59')) / 1000
            this.txTabDat.time = [time1, time2].toString()
          }
          this.changeValue()
        },
        //打款审核审批
        incomeExamine1(id){
          this.$confirm('是否确定打款?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            //审批提现成功
            this.sendMoney(id,1)
          }).catch(() => {
            //审批提现驳回
            this.sendMoney(id,1)
          });
        },
        sendMoney(id,num){
            incomeExamine({income_id:id,income_examine_state:num}).then(res=>{
              console.log('审批结果',res)
              if(res.data.code == '1'){
                this.$message.success(res.info)
              }else{
                this.$message.error(res.info)
              }
            })
        }
    },
    components:{
        Pagination,
        tableModel
    }
}
</script>
<style>
#tiXianManage  .header_search .el-input__inner {
  height: 20px !important;
  border: none !important;
  padding: 0 !important;
}
.invoice-content {
  position: relative;
  /* height: 295px; */
}
.invoice-content li {
  width: 24%;
  float: left;
  margin-bottom: 7px;
  display: block;
  position: relative;
  border-bottom: 1px solid #e5e5e5;
  margin-right: 16px;
  background-color: #fff;
  }
.invoice-content .invoice-orderNum{
  background-color: #0f2c14 !important;
  height: 60px;
}
.invoice-content h1{
    font-size: 18px;
    text-align: center;
    color: #fff;
    line-height: 60px;
    font-weight: normal;
}
.invoice-content li .content{
  padding: 16px;
}
.invoice-content li h4 {
    font-size: 14px;
    color: #616366;
    font-weight: 700;
    margin-bottom: 3px;
    line-height: 20px;
}
.invoice-content li p {
    font-size: 14px;
    color: #333;
}
.invoice-content li .content h1 {
    white-space: nowrap;
    font-size: 14px;
    font-weight: 500;
    color: #323233;
    margin-right: 8px;
}
  .invoice-content li:last-child {
    border: 0px; }
  .invoice-content li span {
    right: 15px;
    display: block;
    font-size: 12px;
    background: #fff;
    padding: 2px 10px;
    border-radius: 16px;
    display: inline-block; }

.invoice-content .invoice-user-img img {
  border-radius: 56px; }

.invoice-content .invoice-info {
  position: relative;
  display: block; }
  .invoice-content .invoice-info p {
    margin-bottom: 5px;margin-left: 10px; }
.invoice-content .right{
  position: absolute;
  right: 20px;
  top: 50%;
}    
.bg-success {
    background-color: #51BB25 !important;
}
</style>